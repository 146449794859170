/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'chart-bubble': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M7.2 11.2c1.77 0 3.2 1.43 3.2 3.2 0 1.77-1.43 3.2-3.2 3.2-1.77 0-3.2-1.43-3.2-3.2 0-1.77 1.43-3.2 3.2-3.2m7.6 4.8a2 2 0 012 2 2 2 0 01-2 2 2 2 0 01-2-2 2 2 0 012-2m.4-12A4.8 4.8 0 0120 8.8c0 2.65-2.15 4.8-4.8 4.8a4.8 4.8 0 01-4.8-4.8c0-2.65 2.15-4.8 4.8-4.8z"/>'
  }
})
