/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'id_card': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M4 4c-1.11 0-2 .89-2 2v12c0 1.11.89 2 2 2h16c1.11 0 2-.89 2-2V6c0-1.11-.89-2-2-2H4m0 2h16v4H4V6m0 6h4v2H4v-2m6 0h10v2H10v-2m-6 4h10v2H4v-2m12 0h4v2h-4v-2z"/>'
  }
})
