/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'expand_more': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M0 0h24v24H0z" _fill="none"/><path pid="1" d="M16.59 8.59L12 13.17 7.41 8.59 6 10l6 6 6-6z"/>'
  }
})
