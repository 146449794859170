/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'chart': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M5 9.2h3V19H5zM10.6 5h2.8v14h-2.8zm5.6 8H19v6h-2.8z"/>'
  }
})
