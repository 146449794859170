/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'chevron_right_double': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M6.41 6L5 7.41 9.58 12 5 16.59 6.41 18l6-6-6-6z"/><path pid="1" d="M13.41 6L12 7.41 16.58 12 12 16.59 13.41 18l6-6-6-6z"/>'
  }
})
