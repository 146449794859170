/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'videogame': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M0 0h24v24H0V0z" _fill="none"/><path pid="1" d="M21 6H3c-1.1 0-2 .9-2 2v8c0 1.1.9 2 2 2h18c1.1 0 2-.9 2-2V8c0-1.1-.9-2-2-2zm0 10H3V8h18v8zM6 15h2v-2h2v-2H8V9H6v2H4v2h2z"/><circle pid="2" cx="14.5" cy="13.5" r="1.5"/><circle pid="3" cx="18.5" cy="10.5" r="1.5"/>'
  }
})
