/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'institution': {
    width: 48,
    height: 48,
    viewBox: '0 0 48 48',
    data: '<path pid="0" d="M4 42V6h19.5v8.25H44v17h-3v-14H23.5v5.25h4v3h-4v5.25h4v3h-4V39h9.75v3zm3-3h5.25v-5.25H7zm0-8.25h5.25V25.5H7zm0-8.25h5.25v-5.25H7zm0-8.25h5.25V9H7zM15.25 39h5.25v-5.25h-5.25zm0-8.25h5.25V25.5h-5.25zm0-8.25h5.25v-5.25h-5.25zm0-8.25h5.25V9h-5.25zM40.65 46v-4.35h-4.4v-3h4.4v-4.4h3v4.4H48v3h-4.35V46zm-7.9-20.5v-3h3v3zm0 8.25v-3h3v3z"/>'
  }
})
