/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'alert': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M13 14h-2V9h2m0 9h-2v-2h2M1 21h22L12 2 1 21z"/>'
  }
})
