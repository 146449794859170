/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'chevron_right': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M0 0h24v24H0z" _fill="none"/><path pid="1" d="M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6z"/>'
  }
})
