/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'mic': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<g _fill="none"><path pid="0" d="M0 0h24v24H0z"/><path pid="1" d="M0 0h24v24H0z"/><path pid="2" d="M0 0h24v24H0z"/></g><path pid="3" d="M12 14c1.66 0 3-1.34 3-3V5c0-1.66-1.34-3-3-3S9 3.34 9 5v6c0 1.66 1.34 3 3 3z"/><path pid="4" d="M17 11c0 2.76-2.24 5-5 5s-5-2.24-5-5H5c0 3.53 2.61 6.43 6 6.92V21h2v-3.08c3.39-.49 6-3.39 6-6.92h-2z"/>'
  }
})
