/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'sentiment_satisfied_alt': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M0 0h24v24H0V0z" _fill="none"/><path pid="1" d="M0 0h24v24H0V0z" _fill="none"/><circle pid="2" cx="15.5" cy="9.5" r="1.5"/><circle pid="3" cx="8.5" cy="9.5" r="1.5"/><circle pid="4" cx="15.5" cy="9.5" r="1.5"/><circle pid="5" cx="8.5" cy="9.5" r="1.5"/><path pid="6" d="M11.99 2C6.47 2 2 6.48 2 12s4.47 10 9.99 10C17.52 22 22 17.52 22 12S17.52 2 11.99 2zM12 20c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8zm0-2.5c2.33 0 4.32-1.45 5.12-3.5h-1.67c-.69 1.19-1.97 2-3.45 2s-2.75-.81-3.45-2H6.88c.8 2.05 2.79 3.5 5.12 3.5z"/>'
  }
})
